import PropTypes from 'prop-types';
import { Button } from 'bv-components';
import classnames from 'classnames';

const Period = ({
  period, selectedPeriodId, handleChange,
}) => {
  const buttonClassNames = classnames('bvs-button-chip is-small', {
    active: period.id === selectedPeriodId,
  });

  return (
    <Button
      label={period.label}
      onClick={handleChange}
      noClass
      className={buttonClassNames}
    />
  );
};

Period.propTypes = {
  period: PropTypes.instanceOf(Object).isRequired,
  selectedPeriodId: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Period;
