import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { toCurrency } from 'bv-helpers/number';
import { formatAmount, formatPendingLimit, NO_LIMIT_PERIOD } from '../helpers';

const CurrentLimitsInfo = ({
  activeLimit,
  pendingLimit,
}) => (
  <div className="sg-portal-section__wrapper with-separator">
    <h3 className="bvs-header">{t('javascript.sg.portal.DEPOSIT_LIMITS.current')}</h3>

    {activeLimit ? (
      <>
        <p>
          {t('javascript.sg.portal.DEPOSIT_LIMITS.equal_to', {
            value: formatAmount(
              activeLimit.period === NO_LIMIT_PERIOD ? null : activeLimit.amount,
              t(`account_form.period.${activeLimit.period}`),
            ),
          })}
        </p>
        {pendingLimit && <p>{formatPendingLimit(pendingLimit)}</p>}
        {activeLimit.period !== NO_LIMIT_PERIOD && (
          <>
            <p>
              {t('javascript.account_form.deposit_limit.remaining_available', {
                value: toCurrency(activeLimit.remaining, { decimalPlaces: 2 }),
              })}
            </p>
            <p>{t(`javascript.sg.portal.DEPOSIT_LIMITS.reset_time.${activeLimit.period}`)}</p>
          </>
        )}
      </>
    ) : (
      <p>{t('javascript.account_form.deposit_limit.not_set')}</p>
    )}
  </div>
);

CurrentLimitsInfo.propTypes = {
  activeLimit: PropTypes.shape({
    amount: PropTypes.number,
    period: PropTypes.string.isRequired,
    remaining: PropTypes.number,
  }),
  pendingLimit: PropTypes.shape({
    amount: PropTypes.number,
    period: PropTypes.string.isRequired,
    effectiveOn: PropTypes.string.isRequired,
  }),
};

CurrentLimitsInfo.defaultProps = {
  activeLimit: null,
  pendingLimit: null,
};

export default CurrentLimitsInfo;
