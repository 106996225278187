import PropTypes from 'prop-types';
import { Spinner, Carousel } from 'bv-components';
import { t } from 'bv-i18n';
import FormWrapper from 'SaferGamblingPortal/components/form_wrapper';
import DetailedDescription from 'SaferGamblingPortal/components/detailed_description';
import ContactUsFooter from 'SharedComponents/contact_us_footer';
import { CardSection } from 'LimitHistory/index';
import { limitType } from 'LimitHistory/helpers';

import { allFieldsSelected } from '../helpers';
import withDepositLimits from '../with_deposit_limits';
import Amounts from './amounts';
import CurrentLimitsInfo from './current_limits_info';
import Period from './period';

const DepositLimits = ({
  hasLoaded,
  activeLimit,
  pendingLimit,
  amounts,
  periods,
  handlePeriodChange,
  handleAmountChange,
  handleSubmit,
  selectedAmount,
  selectedPeriodId,
}) => {
  if (!hasLoaded) return <Spinner />;

  return (
    <>
      <CurrentLimitsInfo
        activeLimit={activeLimit}
        pendingLimit={pendingLimit}
      />
      <FormWrapper
        onSubmit={() => handleSubmit(selectedAmount, selectedPeriodId)}
        className="sg-portal-form"
        headerTitle={t('javascript.sg.portal.DEPOSIT_LIMITS.set')}
        content={(
          <>
            {periods.length > 1 && (
              <div className="sg-portal-form__rows">
                <Carousel scrollStep={234}>
                  <div>
                    {periods.map((period) => (
                      <Period
                        key={period.id}
                        period={period}
                        selectedPeriodId={selectedPeriodId}
                        handleChange={(e) => { e.preventDefault(); handlePeriodChange(period.id); }}
                      />
                    ))}
                  </div>
                </Carousel>
              </div>
            )}
            <Amounts
              values={amounts}
              selectedAmount={selectedAmount}
              handleChange={handleAmountChange}
            />
          </>
        )}
        submitButtonContent={<div>{t('save')}</div>}
        buttonDisabled={!allFieldsSelected(selectedAmount, selectedPeriodId)}
      />
      <DetailedDescription text={t('javascript.sg.portal.SS_NET_DEPOSIT_LIMITS.long_description')} />
      <CardSection type={limitType.DEPOSIT_LIMITS} />
      <ContactUsFooter />
    </>
  );
};

DepositLimits.propTypes = {
  hasLoaded: PropTypes.bool.isRequired,
  activeLimit: PropTypes.shape({
    amount: PropTypes.number,
    period: PropTypes.string.isRequired,
    remaining: PropTypes.number,
  }),
  pendingLimit: PropTypes.shape({
    amount: PropTypes.number,
    period: PropTypes.string.isRequired,
    effectiveOn: PropTypes.string.isRequired,
  }),
  periods: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  amounts: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleAmountChange: PropTypes.func.isRequired,
  handlePeriodChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  selectedAmount: PropTypes.number,
  selectedPeriodId: PropTypes.number,
};

DepositLimits.defaultProps = {
  activeLimit: null,
  pendingLimit: null,
  selectedAmount: null,
  selectedPeriodId: null,
};

export default withDepositLimits(DepositLimits);
